import React from "react"

const Footer = () => (
    <footer className="site-footer">
        <div className="container padding-top">
            <p className="site-footer__footer-title">&copy; Plastfritt Hav</p>
            <p>Alle rettigheter reservert</p>
        </div>
    </footer>
)

export default Footer